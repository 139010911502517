@import "../common/Pages.scss";

.toolkit-page {
  .description {
    width: 100%;
  }
  .graphics {
    position: relative;
    width: 100%;
    max-height: 739px;
    height: 100vh;
    overflow: hidden;

    .graphics__item {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translate(-50%, -30px);
      opacity: 0;
      transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
      display: flex;
      flex-direction: row;
      gap: 102px;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: none;
    }

    .graphics__item.active {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  @media (max-width: 767px) {
    .graphics {
      .graphics__item {
        flex-direction: column;
        max-width: 100%;
        width: 345px;
        gap: 16px;

        svg {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
