@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  justify-content: center;
  background: var(
    --BG-Gradient,
    linear-gradient(
      108deg,
      rgba(255, 252, 237, 0.2) 0%,
      rgba(233, 137, 152, 0.2) 100%
    ),
    #fdfdea
  );
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center; // ✅ 내부 요소 가로 중앙 정렬
  justify-content: center; // ✅ 필요할 경우 세로 중앙 정렬
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 1600px;
  min-height: 100vh; // old browser fallback
  min-height: 100dvh; // modern browser
  max-width: 100vw;
  font-family: "Pretendard","Montserrat", sans-serif;
  text-align: center;
  padding: 40px min(160px, 10%);
  .container {
    width: calc(100% - 40px);
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    padding: 0;
    width: 100%;
    overflow-x: hidden;
    .container {
      width: calc(100% - 20px);
      margin: 0 auto;
    }
  }
}

p,
li {
  word-break: keep-all;
}
