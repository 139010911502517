.pages {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 56px;

  .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 80px;
    margin-top: 50px;

    .mission {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: end;
      gap: 8px;
      color: var(--Text-Black, #000);
      font-weight: 400;
      line-height: normal;

      &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        margin-top: 0;
        margin-bottom: 8px;
        font-size: 32px;
        gap: 5px;

        &_bold {
          margin: 0;
          font-weight: 700;
        }
      }

      &__text,
      &__text_eng {
        margin: 0;
        font-size: 24px;
        z-index: 2;
      }

      &__text {
        color: var(--Text-Black, #000);
      }

      &__text_eng {
        color: var(--Text-Dark-Gray, #666);
      }

      &__title-container {
        position: relative;
        display: inline-block;
      }

      &__highlight-svg {
        position: absolute;
        top: 50%;
        right: -260px;
        transform: translate(-50%, -50%);
        z-index: 0;
      }

      &__title {
        position: relative;
        z-index: 1;
      }
    }

    .intro {
      display: flex;
      flex-direction: column;
      font-weight: bold;
      align-items: flex-start;
      text-align: start;
      gap: 8px;

      &__highlight {
        margin: 0;
        font-size: 32px;
        font-weight: 600;
        color: var(--Text-Black, #000);
      }

      &__subtext {
        margin-top: 16px;
        font-size: 24px;
        font-weight: 400;
        color: var(--Text-Dark-Gray, #666);
      }
    }
  }

  .graphics {
    display: flex;
    justify-content: center;
    gap: 80px;
    margin-top: 80px;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 44px;
      max-width: 344px;

      svg {
        width: 100%;
        height: auto;
        max-width: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    .description {
      gap: 56px;

      .mission {
        &__title {
          font-size: 24px;
        }

        &__text,
        &__text_eng {
          font-size: 16px;
        }
      }

      .intro {
        &__highlight {
          font-size: 22px;
        }

        &__subtext {
          font-size: 16px;
        }
      }
    }

    .graphics {
      flex-direction: column;
      align-items: center;
      gap: 40px;
      margin-top: 40px;

      &__item {
        max-width: 374px;
        gap: 30px;
      }
    }
  }
}
