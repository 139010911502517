.seoul-page {
  max-width: 1600px;
  margin: 0 auto;
  .seoul-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 80px;
    margin-top: 208px;
    margin-bottom: 80px;
    min-width: 311px;
    .left {
      text-align: start !important;
      justify-content: start !important;
    }
    .mission {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: end;
      gap: 8px;
      color: var(--Text-Black, #000);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &__title-container {
        position: relative;
        display: inline-block;
      }
      &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        margin-top: 0;
        margin-bottom: 8px;
        font-size: 32px;
        gap: 5px;
        &_bold {
          margin: 0;
          font-weight: 700;
        }
      }
      &__text-container {
        display: flex;
        flex-direction: column;
        justify-content: end;
        gap: 10px;
      }
      &__text {
        margin: 0;
        font-size: 24px;
        z-index: 2;
      }
      &__text_eng {
        margin: 0;
        color: var(--Text-Dark-Gray, #666);
        font-size: 24px;
        z-index: 2;
      }

      &__highlight-svg {
        position: absolute;
        top: 50%;
        right: -260px;
        transform: translate(-50%, -50%);
        z-index: 0;
      }
      .svg-left {
        left: 0;
      }

      &__title {
        position: relative;
        z-index: 1;
      }
    }

    .intro {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-self: stretch;

      &__img {
        position: absolute;
        right: -100px;
        top: -5%;
        align-items: flex-end;
        width: 70%;
        height: auto;
      }

      &__highlight {
        display: flex;
        justify-content: start;
        flex-direction: column;
        gap: 32px;
        margin: 0;
        width: 30%;
        svg {
          width: 100%;
          height: auto;
          max-width: 100%;
        }
      }
    }
  }

  .graphics {
    display: flex;
    justify-content: space-around;
    margin: 161px 72px 0 72px;

    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 7%;
      width: 45%;

      svg {
        width: 100%;
        height: auto;
        max-width: 100%;
      }
    }
  }

  .graphics__description {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: 600;
    color: var(--Text-Dark-Gray, #666);
  }

  .ebti-program {
    color: #333;
    font-weight: 500;
    &__description {
      text-align: start;
      margin-bottom: 20px;
      margin-top: 34px;
      font-size: 14px;
      p {
        display: flex;
        align-items: center;
        margin: 0;
      }

      ul {
        list-style: none;
        padding: 0;
        gap: 8px;
      }

      li {
        display: flex;
        align-items: center;
        margin: 4px 0;
        font-size: 14px;
      }
      svg {
        width: 24px;
        height: 24px;
        padding-top: 4px;
        margin-right: 8px;
      }
    }

    $colors: (
      ceo: #bfd8ff,
      team: #f5b7b1,
      entrepreneur: #d5e8d4,
    );

    .leadership-container {
      position: relative;
      margin-top: -150px;
      margin-left: 50%;
      margin-bottom: 80px;
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 20px;
      border-radius: 8px;
      text-align: start;
      justify-content: space-between;
      .leadership-item {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #000;
        font-weight: bold;

        .label {
          .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin: 0 5px;
          }

          @each $name, $color in $colors {
            .#{$name} {
              background-color: $color;
            }
          }

          width: 55%;
          font-size: 16px;
          margin-right: auto;
        }

        .title {
          width: 45%;
          font-size: 16px;
          margin-right: auto;
        }
      }
    }

    &__section {
      background: transparent;
      border-radius: 8px;
    }

    &__section-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    &__mobile {
      display: flex;
      flex-direction: column;
      text-align: start;
      margin-bottom: 40px;
      &__label {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        margin-bottom: 24px;

        .expert-tag {
          background-color: #000;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          padding: 4px 12px;
          border-radius: 100px;
          margin-left: 8px;
          white-space: nowrap;
        }
      }
      svg {
        width: 100%;
      }
    }

    &__table {
      width: 100%;
      border-collapse: collapse;
      border-left: none;
      border-right: none;

      th,
      td {
        border: 1px solid #ddd;
        padding: 12px;
        text-align: center;
      }

      th {
        font-weight: bold;
        background-color: #cfe5f3;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
      }

      th:first-child,
      td:first-child {
        border-left: none;
      }

      th:last-child,
      td:last-child {
        border-right: none;
      }

      .action {
        color: #1a80e5;
        font-weight: bold;
        cursor: pointer;
      }

      .action:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 767px) {
    margin-top: 50px;
    .seoul-description {
      gap: 56px;
      margin-top: 108px;
      margin-bottom: 40px;

      .mission {
        &__title {
          font-size: 24px;
        }
        &__title-container {
          svg {
            width: 160px;
            height: 160px;
            right: -160px;
          }
        }
        &__text,
        &__text_eng {
          font-size: 16px;
        }
      }

      .intro {
        &__img {
          position: relative;
          justify-content: center;
          width: 100%;
          left: 0;
        }

        &__highlight {
          width: 100%;
          max-width: 375px;
          svg {
            width: auto;
            height: auto;
            max-width: 100%;
          }
        }
      }
    }

    .graphics {
      flex-direction: column;
      align-items: center;
      gap: 40px;
      margin: 0;
      margin-top: 108px;

      &__item {
        width: 100%;
        max-width: 374px;
        gap: 30px;
        svg {
          width: 100%;
        }
      }
    }
    .ebti-program {
      .leadership-container {
        font-size: 16px;
        margin: 0 0 56px 0;
        width: 100%;
        padding: 0;
        .leadership-item {
          flex-direction: column;
          .label {
            width: 100%;
            font-weight: 600;
          }
          .title {
            width: 100%;
            font-weight: 400;
          }
        }
      }
    }
  }
}
