@import "../common/Pages.scss";

.start-page {
  .graphics {
    gap: 200px !important;

    &__item {
      max-width: 344px !important;
      gap: 44px !important;
      font-size: 32px;
      font-weight: 500;
    }
  }
}
