.header {
  display: flex;
  align-items: start;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  padding: 16px 0px;

  .logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    &__description p {
      margin: 0;
      text-align: start;
      color: var(--Text-Light-Gray, #999);
      font-size: 16px;
      font-weight: 400;
    }
  }

  // ✅ 데스크탑 네비게이션 (기본적으로 보임)
  .nav {
    z-index: 3;
    display: flex;
    width: 65%;
    justify-content: space-between;
    align-items: center;

    &__link {
      padding: 10px 16px;
      text-decoration: none;
      color: var(--Text-Black, #000);
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;

      &.active {
        background-color: #f9e62a;
        border-radius: 20px;
      }
    }
  }

  .auth {
    display: flex;
    gap: 8px;
    align-items: center;

    &__link {
      text-decoration: none;
      color: var(--Text-Black, #000);
      font-size: 16px;
      font-weight: 400;
      padding: 0 10px;
      cursor: pointer;
    }
  }

  // ✅ 햄버거 메뉴 버튼 (모바일 전용)
  .menu-button {
    z-index: 3;
    display: none;
    background: none;
    border: none;
    cursor: pointer;
  }

  // ✅ 모바일 네비게이션 (기본적으로 숨김)
  .mobile-nav-container {
    display: none;
  }

  /* 📱 모바일 반응형 스타일 */
  @media (max-width: 767px) {
    align-items: center;
    .nav {
      display: none; // 데스크탑 네비게이션 숨김
    }

    .logo {
      flex-direction: row; // 모바일에서는 row
      align-items: center;
      svg {
        width: 124px;
        height: 42px;
      }
      &__description {
        p {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }

    .menu-button {
      display: block; // 햄버거 메뉴 활성화
    }

    .mobile-nav-container {
      z-index: 10;
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: end;
      .mobile-nav {
        display: flex;
        flex-direction: column;
        width: 144px;
        height: 100vh;
        background-color: #f9e62a;
        padding: 32px 16px;
        gap: 24px;
        &__link {
          padding: 10px 16px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;
          color: #000;
          &.active {
            background-color: white;
            border-radius: 30px;
          }
        }
      }

      &.open {
        transform: translateX(0);
      }
    }
  }
}
