@import "../common/Pages.scss";

.collabo-page {
  .graphics {
    gap: 18px;
    position: relative;

    .floating-item {
      position: absolute;
      left: 0;
      width: 20%;

      svg {
        width: 100%;
        height: 100%;
        margin-left: 20px;
      }
    }

    &__item {
      max-width: 1465px;
      margin-left: 144px;
    }
  }

  @media (max-width: 767px) {
    .graphics {
      .floating-item {
        position: relative;
        width: 100%;
        max-width: 375px;

        svg {
          margin-left: 0 !important;
        }
      }

      &__item {
        max-width: 345px;
        margin-left: 0px;
        gap: 30px;
      }
    }
  }
}
