@import "../common/Pages.scss";

.labs-page {
  .graphics {
    gap: 18px;

    &__item {
      max-width: 344px;
      gap: 44px;
    }
  }

  .graphics__description {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: 600;
    color: var(--Text-Dark-Gray, #666);
  }

  .labs-info {
    text-align: start;
    margin-top: 80px;
    color: var(--Text-Dark-Gray, #666);

    &__text {
      font-size: 1rem;
      color: #555;
      margin-bottom: 1.5rem;
    }

    &__button {
      display: flex;
      justify-content: center;
      background-color: #000;
      color: #fff;
      padding: 0.75rem 2rem;
      border-radius: 2rem;
      font-size: 1rem;
      cursor: pointer;
      border: none;
      margin-bottom: 1rem;
    }

    &__notice {
      font-size: 0.875rem;
      color: #888;
    }
  }

  @media (max-width: 767px) {
    .graphics {
      &__item {
        max-width: 345px;
        gap: 30px;
      }
    }
  }
}
